import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { findByKey } from "../../utils/helpers";
import { current } from "@reduxjs/toolkit";
import {
  getCityStateByPincode,
  getStoreFrontDetailsApi,
  getUserData,
  updateUserData,
} from "./UserApi";

const initialState = {
  loading: false,
  error: null,
  success: false,
  user: null,
  selectedAddress: null,
  storeFront: { details: null, about: null },
};

export const getUserThunk = createAsyncThunk(
  "user/getUser",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getUserData();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getCityStateByPincodeThunk = createAsyncThunk(
  "user/getCityStateByPincode",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getCityStateByPincode(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addAddressThunk = createAsyncThunk(
  "user/addAddressThunk",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await updateUserData(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getStoreFrontDetailsThunk = createAsyncThunk(
  "user/store/getStoreDetails",
  async ({ storeId, type }, { rejectWithValue }) => {
    try {
      const response = await getStoreFrontDetailsApi(storeId, type);

      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const setSelectedAddressThunk = createAsyncThunk(
  "user/setAddreess",
  async (payload, { rejectWithValue }) => {
    try {
      return payload;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reset: () => initialState,
    setSelectedAddress: (state, action) => {
      const { addressId } = action.payload;
      const originalState = current(state);

      const address = findByKey(
        [...originalState.user.address],
        "id",
        addressId
      );

      // Only update `selectedAddress` if the address is found
      if (address) {
        state.selectedAddress = address;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getUserThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;

        if (!state.selectedAddress && state.user?.address?.length) {
          state.selectedAddress = state.user.address[0];
        }
        localStorage.setItem("firstName", state.user?.first_name ?? "");
        localStorage.setItem("lastName", state.user?.last_name ?? "");
        localStorage.setItem("email", state.user?.email ?? "");
        localStorage.setItem("username", state.user?.user_name ?? "");
      })
      .addCase(getUserThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getCityStateByPincodeThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getCityStateByPincodeThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
      })
      .addCase(getCityStateByPincodeThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.success = false;
      })
      .addCase(addAddressThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(addAddressThunk.fulfilled, (state, action) => {
        state.loading = false;

        const addedAddress = action.payload.user_address_response;

        if (addedAddress) {
          state.user?.address.push(addedAddress);
          state.selectedAddress =
            state.user?.address.find(
              (address) => address.id === addedAddress.id
            ) || null;
        }

        state.error = null;
      })
      .addCase(addAddressThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.success = false;
      })
      .addCase(getStoreFrontDetailsThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getStoreFrontDetailsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.storeFront.details = action.payload.data;
      })
      .addCase(getStoreFrontDetailsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(setSelectedAddressThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(setSelectedAddressThunk.fulfilled, (state, action) => {
        state.loading = false;
        const { addressId } = action.payload;

        const address = findByKey([...state.user.address], "id", addressId);

        // Only update `selectedAddress` if the address is found
        if (address) {
          state.selectedAddress = address;
        }
      })
      .addCase(setSelectedAddressThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default userSlice.reducer;
export const { reset } = userSlice.actions;
